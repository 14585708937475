@import "dashlite/css/dashlite.min.css";

* {
  font-family: "Montserrat", sans-serif !important;
}

.chart-400 {
  height: 400px !important;
  width: 100% !important;
}

.max-width-450 {
  max-width: 450px !important;
}

.max-width-600 {
  max-width: 600px !important;
}

.text-dimmed {
  color: #8094ae !important;
}

.table-condensed {
  font-size: 12px;
}

.subtitle,
.amount {
  color: #364a63 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat, sans-serif !important;
}

.min-width-150 {
  min-width: 150px !important;
}

.width-75 {
  width: 75px !important;
}

.width-150 {
  width: 150px !important;
}

.width-600 {
  width: 600px !important;
}

.active .icon {
  color: #7f8dff !important;
}

.ni-help {
  color: #a0a0a0 !important;
}

.blix-logo {
  height: 50px;
}

.img-fluid {
  max-height: 550px;
  width: auto;
}

button, .btn {
  max-width: 200px !important;
}

strong {
  font-weight: 800;
}

.custom-alert {
  padding: 15px;
  margin: 5px;
}

.hidden-in-pdf {
  display: none !important;
}

.avoid-page-break {
  page-break-inside: avoid;
  break-inside: avoid;
}

.custom-select {
  margin: 7px;
}

.dataTables_filter {
  input {
    margin: 7px;
  }
}

.dt-buttons {
  display: block;
  text-align: left !important;
}

@media print {
  .nk-header,
  button {
    display: none !important;
    font-size: 1.5rem !important;
    font-weight: 500 !important;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  * {
    zoom: 1.0;
  }
}

@media (min-width: 992px) {
  .mobile-banner {
    display: none;
  }

  .mobile-logout {
    display: none;
  }
}

@media (max-width: 992px) {
  .navbar-scroll {
    overflow-x: auto;
  }

  .desktop-logout {
    display: none;
  }
}

@media (max-width: 768px) {
  .summary-text {
    display: none;
  }
}

@media print {
  .nk-footer {
    display: none !important;
  }

  .btn {
    display: none !important;
  }

  .card-hint {
    display: none !important;
  }
}


@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fonts/Montserrat.ttf");
}
